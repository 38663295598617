* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  font-family: "Poppins";
  overflow: hidden !important;
}

.webgl {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}
